import { Stacked } from "./components";

import { Bar,Line } from "./pages";
const AllGraphs =() =>{

    return (
        <div>
        <h1>All graphical Data</h1>
        <Stacked/>
        <Line/>
        <Bar/>

        </div>
        )
}

export default AllGraphs